import React, { useState, useEffect } from "react";
import "../Styles/PageStyles/ViewPage.css";
import { FaEdit, FaTrashAlt, FaEye } from "react-icons/fa";
import axios from "axios";
import EditModal from "../Components/EditModal";
import ViewModal from "../Components/ViewModal";
function ViewPage() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const openViewModal = (caseItem) => {
    setSelectedCase(caseItem);
    setIsViewModalOpen(true);
  };

  useEffect(() => {
    fetchCases();
  }, []);

  const fetchCases = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://backend.bardagoriyalegalsystem.com/api/cases", {
        params: {
          caseStatus: "Pending",
        },
      });
      

      
      const transformedData = response.data.map((caseItem, index) => ({
        id: caseItem._id,
        plaintiff: `${caseItem.witness.firstName} ${caseItem.witness.middleName || ""} ${caseItem.witness.lastName}`.trim(),
        defendant: `${caseItem.defendant.firstName} ${caseItem.defendant.middleName || ""} ${caseItem.defendant.lastName}`.trim(),
        amount: caseItem.caseDetails.caseFee.toString(),
        issue: caseItem.caseDetails.caseInfo,
        date: new Date(caseItem.caseDetails.caseRegisterDate).toISOString().split("T")[0],
        caseNo: caseItem.caseDetails.caseFilingNumber,
        status: caseItem.caseDetails.caseStatus
      }));
      setData(transformedData);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch cases. Please try again later.");
      setLoading(false);
    }
  };

  const handleEdit = (caseItem) => {
    setSelectedCase(caseItem);
    setIsModalOpen(true);
  };

  const handleSave = async (updatedCase) => {
    try {
      const payload = {
        witness: {
          firstName: updatedCase.firstName,
          middleName: updatedCase.middleName,
          lastName: updatedCase.lastName,
          DOB: updatedCase.DOB,
          age: updatedCase.age,
          gender: updatedCase.gender,
          mobileNumber: updatedCase.mobileNumber,
          citizenNumber: updatedCase.citizenNumber,
          citizenDate: updatedCase.citizenDate,
          grandFatherName: updatedCase.grandFatherName,
          fatherName: updatedCase.fatherName,
          Address: {
            Province: updatedCase.Province,
            District: updatedCase.District,
            Municipalities: updatedCase.Municipalities,
            Ward: updatedCase.Ward,
          },
        },
        defendant: {
          firstName: updatedCase.defendantFirstName,
          middleName: updatedCase.defendantMiddleName,
          lastName: updatedCase.defendantLastName,
          DOB: updatedCase.defendantDOB,
          age: updatedCase.defendantAge,
          gender: updatedCase.defendantGender,
          mobileNumber: updatedCase.defendantMobileNumber,
          citizenNumber: updatedCase.defendantCitizenNumber,
          citizenDate: updatedCase.defendantCitizenDate,
          grandFatherName: updatedCase.defendantGrandFatherName,
          fatherName: updatedCase.defendantFatherName,
          Address: {
            Province: updatedCase.defendantProvince,
            District: updatedCase.defendantDistrict,
            Municipalities: updatedCase.defendantMunicipalities,
            Ward: updatedCase.defendantWard,
          },
        },
        caseDetails: {
          caseName: updatedCase.caseName,
          caseType: updatedCase.caseType,
          caseRegisterDate: updatedCase.caseRegisterDate,
          caseStatus: updatedCase.caseStatus,
          caseFilingNumber: updatedCase.caseFilingNumber,
          caseFirstVisit: updatedCase.caseFirstVisit,
          caseVisitTime: updatedCase.caseVisitTime,
          caseFee: updatedCase.caseFee,
          caseInfo: updatedCase.caseInfo,
        },
        courtDetails: {
          courtType: updatedCase.courtType,
          courtName: updatedCase.courtName,
          judgeName: updatedCase.judgeName,
          judgePosition: updatedCase.judgePosition,
          remarks: updatedCase.remarks,
        },
      };
  
      const response = await axios.put(
        `https://backend.bardagoriyalegalsystem.com/api/cases/${updatedCase.id}`,
        payload
      );
  
      console.log("Update successful:", response.data);
      setData((prevData) =>
        prevData.map((item) => (item.id === updatedCase.id ? response.data : item))
      );
  
      setIsModalOpen(false);
    } catch (error) {
      console.error('Failed to update case:', error.response?.data || error.message);
      alert('Failed to update case. Please try again.');
    }
  };
  

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://backend.bardagoriyalegalsystem.com/api/cases/${id}`);
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      alert("Failed to delete the case.");
    }
  };

  const filteredData = data.filter((item) => {
    const searchLower = search.toLowerCase();
  
    const plaintiffMatch = typeof item?.plaintiff === 'string' 
      ? item.plaintiff.toLowerCase().includes(searchLower) 
      : false;
  
    const defendantMatch = typeof item?.defendant === 'string' 
      ? item.defendant.toLowerCase().includes(searchLower) 
      : false;
  
    const issueMatch = typeof item?.issue === 'string' 
      ? item.issue.toLowerCase().includes(searchLower) 
      : false;
  
    return plaintiffMatch || defendantMatch || issueMatch;
  });
  

  if (loading) return <div className="loading">Loading cases...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="table-container">
      <div className="search-container">
        <input type="text" placeholder="Search by plaintiff, defendant, or issue..." value={search} onChange={(e) => setSearch(e.target.value)} className="search-input" />
      </div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>क्र.स</th>
            <th>वादी र प्रतिवादी</th>
            <th>रकम र विवादको विषय</th>
            <th>मु.दा.नं. र दर्ता मिति</th>
            <th>मुद्दाको चरण</th>
            <th>कार्य</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length === 0 ? (
            <tr>
              <td colSpan="6" className="no-data">No cases found</td>
            </tr>
          ) : (
            filteredData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <div className="plaintiff">{item.plaintiff}</div>
                  <div className="defendant">{item.defendant}</div>
                </td>
                <td>
                  <div className="amount">{item.amount}</div>
                  <div className="issue">{item.issue}</div>
                </td>
                <td>
                  <div className="case-no">{item.caseNo || "-"}</div>
                  <div className="date">{item.date}</div>
                </td>
                <td>{item.status || "मुद्दा दर्ता"}</td>
                <td className="actions">
                <button className="view-btn" onClick={() => openViewModal(item)}>
                    <FaEye className="icon" /> View
                  </button>
                  <button className="edit-btn" onClick={() => handleEdit(item)}>
                    <FaEdit className="icon" /> Edit
                  </button>
                  <button className="delete-btn" onClick={() => handleDelete(item.id)}>
                    <FaTrashAlt className="icon" /> Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <ViewModal isOpen={isViewModalOpen} onClose={() => setIsViewModalOpen(false)} caseData={selectedCase} />
      <EditModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} caseData={selectedCase} handleSave={handleSave} />
    </div>
  );
}

export default ViewPage;
