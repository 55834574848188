import React, { useState, useEffect } from 'react';
import '../Styles/PageStyles/JudgeManagement.css';

const JudgeManagement = () => {
  const [judgePositions, setJudgePositions] = useState([]);
  const [judgeNames, setJudgeNames] = useState([]);
  const [judgePositionInput, setJudgePositionInput] = useState('');
  const [judgeNameInput, setJudgeNameInput] = useState('');
  const [error, setError] = useState('');

  // Fetch judge positions and judge names
  useEffect(() => {
    const fetchJudgePositions = async () => {
      try {
        const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/judge-positions');
        const data = await response.json();
        setJudgePositions(data);
      } catch (err) {
        setError('Failed to fetch judge positions');
      }
    };

    const fetchJudgeNames = async () => {
      try {
        const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/judge-names');
        const data = await response.json();
        setJudgeNames(data);
      } catch (err) {
        setError('Failed to fetch judge names');
      }
    };

    fetchJudgePositions();
    fetchJudgeNames();
  }, []);

  // Add judge position
  const addJudgePosition = async () => {
    try {
      const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/judge-positions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ judgePosition: judgePositionInput }),
      });
      const data = await response.json();
      setJudgePositions([...judgePositions, data]);
      setJudgePositionInput('');
    } catch (err) {
      setError('Failed to add judge position');
    }
  };

  // Add judge name
  const addJudgeName = async () => {
    try {
      const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/judge-names', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ judgeName: judgeNameInput }),
      });
      const data = await response.json();
      setJudgeNames([...judgeNames, data]);
      setJudgeNameInput('');
    } catch (err) {
      setError('Failed to add judge name');
    }
  };

  // Delete judge position
  const deleteJudgePosition = async (id) => {
    try {
      await fetch(`https://backend.bardagoriyalegalsystem.com/api/judge-positions/${id}`, {
        method: 'DELETE',
      });
      setJudgePositions(judgePositions.filter((judge) => judge._id !== id));
    } catch (err) {
      setError('Failed to delete judge position');
    }
  };

  // Delete judge name
  const deleteJudgeName = async (id) => {
    try {
      await fetch(`https://backend.bardagoriyalegalsystem.com/api/judge-names/${id}`, {
        method: 'DELETE',
      });
      setJudgeNames(judgeNames.filter((judge) => judge._id !== id));
    } catch (err) {
      setError('Failed to delete judge name');
    }
  };

  return (
    <div className="judge-management-container">
      <h1>न्यायाधीश व्यवस्थापन</h1>
      {error && <div className="error">{error}</div>}

      <div className="forms-container">
        <div className="form-box">
          <h2>Add Judge Position</h2>
          <input
            type="text"
            value={judgePositionInput}
            onChange={(e) => setJudgePositionInput(e.target.value)}
            placeholder="Enter Judge Position"
          />
          <button onClick={addJudgePosition}>Add Judge Position</button>
        </div>

        <div className="form-box">
          <h2>Add Judge Name</h2>
          <input
            type="text"
            value={judgeNameInput}
            onChange={(e) => setJudgeNameInput(e.target.value)}
            placeholder="Enter Judge Name"
          />
          <button onClick={addJudgeName}>Add Judge Name</button>
        </div>
      </div>

      <div className="tables-container">
        <div className="table-box">
          <h2>Judge Positions</h2>
          <table>
            <thead>
              <tr>
                <th>Judge Position</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {judgePositions.map((judge) => (
                <tr key={judge._id}>
                  <td>{judge.judgePosition}</td>
                  <td>
                    <button
                      className="delete-btn"
                      onClick={() => deleteJudgePosition(judge._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-box">
          <h2>Judge Names</h2>
          <table>
            <thead>
              <tr>
                <th>Judge Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {judgeNames.map((judge) => (
                <tr key={judge._id}>
                  <td>{judge.judgeName}</td>
                  <td>
                    <button
                      className="delete-btn"
                      onClick={() => deleteJudgeName(judge._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JudgeManagement;
