import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../Styles/ComponentStyles/Layout.css';

const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login"; 
  return (
    <div className={`layout ${isLoginPage ? "no-sidebar" : ""}`}>
      {!isLoginPage  && <Sidebar />}
      <div className="main-content">{children}</div>
    </div>
  );
};

export default Layout;
