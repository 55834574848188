import React, { useState, useEffect } from 'react';
import '../Styles/PageStyles/CaseManagement.css';

const CaseManagement = () => {
  const [caseNames, setCaseNames] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [caseNameInput, setCaseNameInput] = useState('');
  const [caseTypeInput, setCaseTypeInput] = useState('');
  const [error, setError] = useState('');






 // Add case name
 const addCaseName = async () => {
    try {
      const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/case-names', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ caseName: caseNameInput }),
      });
      const data = await response.json();
      setCaseNames([...caseNames, data]);
      setCaseNameInput('');
    } catch (err) {
      setError('Failed to add case name');
    }
  };

  // Add case type

  const addCaseType = async () => {
    try {
      const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/case-types', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ caseType: caseTypeInput }),
      });
      const data = await response.json();      
        setCaseTypes([...caseTypes, data]);
        setCaseTypeInput('');
    } catch (err) {
      setError('Failed to add case type');
    }
  };
  



  // Fetch case names and case types
  useEffect(() => {

    const fetchCaseNames = async () => {
      try { 
        const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/case-names');
        const data = await response.json();
        setCaseNames(data);
      } catch (err) {
        setError('Failed to fetch case names');
      }
    };

    const fetchCaseTypes = async () => {
        try {
          const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/case-types');
          const data = await response.json();
          console.log("Fetched Case Types:", data); // Debugging output
          setCaseTypes(data);
        } catch (err) {
          setError('Failed to fetch case types');
        }
      };
      
    fetchCaseNames();
    fetchCaseTypes();
  }, []);

  // Delete case name
  const deleteCaseName = async (id) => {
    try {
      const response = await fetch(`https://backend.bardagoriyalegalsystem.com/api/case-names/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (data.message === 'Case name deleted successfully') {
        setCaseNames(caseNames.filter((caseName) => caseName._id !== id));
      }
    } catch (err) {
      setError('Failed to delete case name');
    }
  };

  // Delete case type
  const deleteCaseType = async (id) => {
    try {
      const response = await fetch(`https://backend.bardagoriyalegalsystem.com/api/case-types/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (data.message === 'Case type deleted successfully') {
        setCaseTypes(caseTypes.filter((caseType) => caseType._id !== id));
      }
    } catch (err) {
      setError('Failed to delete case type');
    }
  };

  return (
    <div className="case-management-container">
      <h1>मामिला व्यवस्थापन</h1>
      {error && <div className="error">{error}</div>}

      <div className="forms-container">
        <div className="form-box">
          <h2>मामिलाको नाम</h2>
          <input
            type="text"
            value={caseNameInput}
            onChange={(e) => setCaseNameInput(e.target.value)}
            placeholder="Enter Case Name"
          />
          <button onClick={addCaseName}>मामिलाको नाम</button>
        </div>

        <div className="form-box">
          <h2>मामिलाको प्रकार</h2>
          <input
            type="text"
            value={caseTypeInput}
            onChange={(e) => setCaseTypeInput(e.target.value)}
            placeholder="Enter Case Type"
          />
          <button onClick={addCaseType}>मामिलाको प्रकार</button>
        </div>
      </div>

      <div className="tables-container">
        <div className="table-box">
          <h2>मामिलाको नाम</h2>
          <table>
            <thead>
              <tr>
                <th>Case Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {caseNames.map((caseName) => (
                <tr key={caseName._id}>
                  <td>{caseName.caseName}</td>
                  <td>
                    <button className="delete-btn" onClick={() => deleteCaseName(caseName._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-box">
          <h2>Case Types</h2>
          <table>
            <thead>
              <tr>
                <th>Case Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {caseTypes.map((caseType) => (
                <tr key={caseType._id}>
                  <td>{caseType.caseType}</td>
                  <td>
                    <button className="delete-btn" onClick={() => deleteCaseType(caseType._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CaseManagement;
