import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import {
  FaHome,
  FaUser,
  FaEnvelope,
  FaChevronDown,
  FaChevronRight,
  FaClock,
  FaSpinner,
  FaCheckCircle,
  FaBars,
  FaTimes,
  FaSignOutAlt,  
} from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { GiClawHammer } from "react-icons/gi";
import { IoSettingsSharp } from "react-icons/io5";
import "../Styles/ComponentStyles/Sidebar.css";
import Logo from "../Img/logo.png";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook for navigation
  const [isCaseMenuOpen, setCaseMenuOpen] = useState(false);
  const [isSettingMenuOpen, setSettingMenuOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setSidebarOpen(true); // Keep sidebar open on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle Logout
  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove the token from localStorage
    navigate("/login"); // Redirect to the login page
  };

  return (
    <>
      {/* Show Toggle Button Only on Mobile View */}
      {isMobile && (
        <button className="toggle-btn" onClick={() => setSidebarOpen(!isSidebarOpen)}>
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </button>
      )}

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <div className="sidebar-header">
          <img src={Logo} className="sidebar-logo" alt="Logo" />
          <div className="sidebar-title">
            <h2>बर्दगोरिया गाउँपालिका</h2>
            <p>सुदूरपश्चिम प्रदेश, मुडा कैलाली</p>
          </div>
        </div>

        <ul className="sidebar-menu">
          <li>
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              <FaHome className="icon" /> मुद्दा दर्ता
            </Link>
          </li>

          {/* मुद्दा सुची */}
          <li className="menu-item" onClick={() => setCaseMenuOpen(!isCaseMenuOpen)}>
            <div className="menu-link">
              <FaUser className="icon" />
              <span>मुद्धा सुची</span>
              {isCaseMenuOpen ? <FaChevronDown className="arrow" /> : <FaChevronRight className="arrow" />}
            </div>
          </li>
          {isCaseMenuOpen && (
            <ul className="submenu">
              <li>
                <Link
                  to="/view-case/pending"
                  className={location.pathname === "/view-case/pending" ? "active" : ""}
                >
                  <FaClock className="icon" /> प्रकिया
                </Link>
              </li>
              <li>
                <Link
                  to="/view-case/processing"
                  className={location.pathname === "/view-case/processing" ? "active" : ""}
                >
                  <FaSpinner className="icon" /> तामेली
                </Link>
              </li>
              <li>
                <Link
                  to="/view-case/completed"
                  className={location.pathname === "/view-case/completed" ? "active" : ""}
                >
                  <FaCheckCircle className="icon" /> सम्पन्न
                </Link>
              </li>
            </ul>
          )}

          {/* व्यवस्थापन */}
          <li className="menu-item" onClick={() => setSettingMenuOpen(!isSettingMenuOpen)}>
            <div className="menu-link">
              <IoSettingsSharp className="icon" />
              <span>व्यवस्थापन</span>
              {isSettingMenuOpen ? <FaChevronDown className="arrow" /> : <FaChevronRight className="arrow" />}
            </div>
          </li>
          {isSettingMenuOpen && (
            <ul className="submenu">
              <li>
                <Link to="/setting/case" className={location.pathname === "/setting/case" ? "active" : ""}>
                  <GoLaw className="icon" /> मामिला व्यवस्थापन
                </Link>
              </li>
              <li>
                <Link to="/setting/court" className={location.pathname === "/setting/court" ? "active" : ""}>
                  <GiClawHammer className="icon" /> अदालत व्यवस्थापन
                </Link>
              </li>
              <li>
                <Link to="/setting/judge" className={location.pathname === "/setting/judge" ? "active" : ""}>
                  <FaUser className="icon" /> न्यायाधीश व्यवस्थापन
                </Link>
              </li>
            </ul>
          )}

          {/* Logout Button */}
          <li onClick={handleLogout}>
            <div className="menu-link" style={{marginTop:'10px', cursor:'pointer'}}>
              <FaSignOutAlt className="icon" />
              <span>लगआउट</span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
