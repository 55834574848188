import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Components/Layouts';
import HomePage from './Pages/HomePage';
import ViewPage from './Pages/ViewPage';
import ProcessingPage from './Pages/ProcessingPage';
import CompletedPage from './Pages/CompletedPage';
import CourtManagement from './Pages/CourtManagement';
import JudgeManagement from './Pages/JudgeManagement';
import CaseManagement from './Pages/CaseManagement';
import Login from './Pages/Login';
import PrivateRoute from './Data/PrivateRoute'; // Import PrivateRoute

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/login' element={<Login />} /> {/* Login route is public */}

          {/* Protected routes */}
          <Route
            path="/"
            element={<PrivateRoute element={<HomePage />} />}
          />
          <Route
            path="/view-case/pending"
            element={<PrivateRoute element={<ViewPage />} />}
          />
          <Route
            path="/view-case/processing"
            element={<PrivateRoute element={<ProcessingPage />} />}
          />
          <Route
            path="/view-case/completed"
            element={<PrivateRoute element={<CompletedPage />} />}
          />
          <Route
            path="/setting/court"
            element={<PrivateRoute element={<CourtManagement />} />}
          />
          <Route
            path="/setting/judge"
            element={<PrivateRoute element={<JudgeManagement />} />}
          />
          <Route
            path="/setting/case"
            element={<PrivateRoute element={<CaseManagement />} />}
          />

          <Route path="*" element={<Login />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
