import React, { useState, useEffect } from "react";
import "../Styles/ComponentStyles/EditModal.css";
import provincesData from "../Data/ProvincesData";

function EditModal({ isOpen, onClose, caseData, handleSave }) {
  const [formData, setFormData] = useState({
    id: "",
    // Witness data
    firstName: "",
    middleName: "",
    lastName: "",
    DOB: "",
    age: "",
    gender: "",
    mobileNumber: "",
    citizenNumber: "",
    citizenDate: "",
    grandFatherName: "",
    fatherName: "",
    Province: "",
    District: "",
    Municipalities: "",
    Ward: "",
    
    // Defendant data
    defendantFirstName: "",
    defendantMiddleName: "",
    defendantLastName: "",
    defendantDOB: "",
    defendantAge: "",
    defendantGender: "",
    defendantMobileNumber: "",
    defendantCitizenNumber: "",
    defendantCitizenDate: "",
    defendantGrandFatherName: "",
    defendantFatherName: "",
    defendantProvince: "",
    defendantDistrict: "",
    defendantMunicipalities: "",
    defendantWard: "",
    
    // Case details
    caseName: "",
    caseType: "",
    caseRegisterDate: "",
    caseStatus: "",
    caseFilingNumber: "",
    caseFirstVisit: "",
    caseVisitTime: "",
    caseFee: "",
    caseInfo: "",
    
    // Court details
    courtType: "",
    courtName: "",
    judgeName: "",
    judgePosition: "",
    remarks: "",
  });

  useEffect(() => {
    if (caseData) {
      // Fetch the complete case data using the ID
      const fetchCompleteCase = async () => {
        try {
          const response = await fetch(`https://backend.bardagoriyalegalsystem.com/api/cases/${caseData.id}`);
          const completeData = await response.json();
          
          // First set the province fields so that districts can be correctly loaded
          setFormData({
            id: caseData.id,
            // First set just the provinces
            Province: completeData.witness.Address.Province || "",
            defendantProvince: completeData.defendant.Address.Province || "",
            // Set all other fields
            firstName: completeData.witness.firstName || "",
            middleName: completeData.witness.middleName || "",
            lastName: completeData.witness.lastName || "",
            DOB: completeData.witness.DOB ? completeData.witness.DOB.split('T')[0] : "",
            age: completeData.witness.age || "",
            gender: completeData.witness.gender || "",
            mobileNumber: completeData.witness.mobileNumber || "",
            citizenNumber: completeData.witness.citizenNumber || "",
            citizenDate: completeData.witness.citizenDate ? completeData.witness.citizenDate.split('T')[0] : "",
            grandFatherName: completeData.witness.grandFatherName || "",
            fatherName: completeData.witness.fatherName || "",
            // District and Municipality will be set in the next useEffect
            Ward: completeData.witness.Address.Ward || "",
            
            // Defendant fields
            defendantFirstName: completeData.defendant.firstName || "",
            defendantMiddleName: completeData.defendant.middleName || "",
            defendantLastName: completeData.defendant.lastName || "",
            defendantDOB: completeData.defendant.DOB ? completeData.defendant.DOB.split('T')[0] : "",
            defendantAge: completeData.defendant.age || "",
            defendantGender: completeData.defendant.gender || "",
            defendantMobileNumber: completeData.defendant.mobileNumber || "",
            defendantCitizenNumber: completeData.defendant.citizenNumber || "",
            defendantCitizenDate: completeData.defendant.citizenDate ? completeData.defendant.citizenDate.split('T')[0] : "",
            defendantGrandFatherName: completeData.defendant.grandFatherName || "",
            defendantFatherName: completeData.defendant.fatherName || "",
            // District and Municipality will be set in the next useEffect
            defendantWard: completeData.defendant.Address.Ward || "",
            
            // Case details
            caseName: completeData.caseDetails.caseName || "",
            caseType: completeData.caseDetails.caseType || "",
            caseRegisterDate: completeData.caseDetails.caseRegisterDate ? completeData.caseDetails.caseRegisterDate.split('T')[0] : "",
            caseStatus: completeData.caseDetails.caseStatus || "",
            caseFilingNumber: completeData.caseDetails.caseFilingNumber || "",
            caseFirstVisit: completeData.caseDetails.caseFirstVisit ? completeData.caseDetails.caseFirstVisit.split('T')[0] : "",
            caseVisitTime: completeData.caseDetails.caseVisitTime || "",
            caseFee: completeData.caseDetails.caseFee || "",
            caseInfo: completeData.caseDetails.caseInfo || "",
            
            // Court details
            courtType: completeData.courtDetails.courtType || "",
            courtName: completeData.courtDetails.courtName || "",
            judgeName: completeData.courtDetails.judgeName || "",
            judgePosition: completeData.courtDetails.judgePosition || "",
            remarks: completeData.courtDetails.remarks || "",
          });
          
          // Now we need to set the district and municipalities after a small delay to ensure the province data is ready
          setTimeout(() => {
            setFormData(prevFormData => ({
              ...prevFormData,
              District: completeData.witness.Address.District || "",
              defendantDistrict: completeData.defendant.Address.District || "",
            }));
            
            // Set municipalities after another small delay
            setTimeout(() => {
              setFormData(prevFormData => ({
                ...prevFormData,
                Municipalities: completeData.witness.Address.Municipalities || "",
                defendantMunicipalities: completeData.defendant.Address.Municipalities || "",
              }));
            }, 50);
          }, 50);
          
        } catch (error) {
          console.error("Failed to fetch complete case:", error);
        }
      };
      
      fetchCompleteCase();
    }
  }, [caseData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Get witness districts based on selected province
  const witnessDistricts = formData.Province
    ? provincesData.provinceList.find(p => p.name === formData.Province)?.districtList || []
    : [];

  // Get witness municipalities based on selected district
  const witnessMunicipalities = formData.District
    ? witnessDistricts.find(d => d.name === formData.District)?.municipalityList || []
    : [];

  // Get defendant districts based on selected province
  const defendantDistricts = formData.defendantProvince
    ? provincesData.provinceList.find(p => p.name === formData.defendantProvince)?.districtList || []
    : [];

  // Get defendant municipalities based on selected district
  const defendantMunicipalities = formData.defendantDistrict
    ? defendantDistricts.find(d => d.name === formData.defendantDistrict)?.municipalityList || []
    : [];

  const handleProvinceChange = (field, value) => {
    if (field === 'Province') {
      setFormData(prev => ({
        ...prev,
        Province: value,
        District: '',
        Municipalities: ''
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        defendantProvince: value,
        defendantDistrict: '',
        defendantMunicipalities: ''
      }));
    }
  };

  const handleDistrictChange = (field, value) => {
    if (field === 'District') {
      setFormData(prev => ({
        ...prev,
        District: value,
        Municipalities: ''
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        defendantDistrict: value,
        defendantMunicipalities: ''
      }));
    }
  };

  // If modal is not open, don't render anything
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>मामिला सम्पादन</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSave(formData);
          }}>
            {/* Witness Section */}
            <section className="form-section">
              <h3>प्रथम पक्ष</h3>
              <div className="form-row">
                <div className="form-group">
                  <label>पहिलो नाम</label>
                  <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>मध्य नाम</label>
                  <input type="text" name="middleName" value={formData.middleName} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>थर</label>
                  <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>जन्म मिति</label>
                  <input type="date" name="DOB" value={formData.DOB} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>उमेर</label>
                  <input type="number" name="age" value={formData.age} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>लिङ्ग</label>
                  <select name="gender" value={formData.gender} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="male">पुरुष</option>
                    <option value="female">महिला</option>
                    <option value="others">अर्को</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>मोबाइल नम्बर</label>
                  <input type="tel" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>नागरिकता नम्बर</label>
                  <input type="text" name="citizenNumber" value={formData.citizenNumber} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>नागरिकता मिति</label>
                  <input type="date" name="citizenDate" value={formData.citizenDate} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>हजुरबुवाको नाम</label>
                  <input type="text" name="grandFatherName" value={formData.grandFatherName} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>बाबुको नाम</label>
                  <input type="text" name="fatherName" value={formData.fatherName} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>प्रदेश</label>
                  <select
                    name="Province"
                    value={formData.Province}
                    onChange={(e) => handleProvinceChange('Province', e.target.value)}
                    required
                  >
                    <option value="">प्रदेश चयन गर्नुहोस्</option>
                    {provincesData.provinceList.map((province) => (
                      <option key={province.id} value={province.name}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>जिल्ला</label>
                  <select
                    name="District"
                    value={formData.District}
                    onChange={(e) => handleDistrictChange('District', e.target.value)}
                    disabled={!formData.Province}
                    required
                  >
                    <option value="">जिल्ला चयन गर्नुहोस्</option>
                    {witnessDistricts.map((district) => (
                      <option key={district.id} value={district.name}>
                        {district.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>पालिका</label>
                  <select
                    name="Municipalities"
                    value={formData.Municipalities}
                    onChange={handleInputChange}
                    disabled={!formData.District}
                    required
                  >
                    <option value="">पालिका चयन गर्नुहोस्</option>
                    {witnessMunicipalities.map((municipality) => (
                      <option key={municipality.id} value={municipality.name}>
                        {municipality.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>वडाः</label>
                  <input type="number" name="Ward" value={formData.Ward} onChange={handleInputChange} required />
                </div>
              </div>
            </section>

            {/* Defendant Section */}
            <section className="form-section">
              <h3>दोश्रो पक्ष</h3>
              <div className="form-row">
                <div className="form-group">
                  <label>पहिलो नाम</label>
                  <input type="text" name="defendantFirstName" value={formData.defendantFirstName} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>मध्य नाम</label>
                  <input type="text" name="defendantMiddleName" value={formData.defendantMiddleName} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>थर</label>
                  <input type="text" name="defendantLastName" value={formData.defendantLastName} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>जन्म मिति</label>
                  <input type="date" name="defendantDOB" value={formData.defendantDOB} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>उमेर</label>
                  <input type="number" name="defendantAge" value={formData.defendantAge} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>लिङ्ग</label>
                  <select name="defendantGender" value={formData.defendantGender} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="male">पुरुष</option>
                    <option value="female">महिला</option>
                    <option value="others">अर्को</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>मोबाइल नम्बर</label>
                  <input type="tel" name="defendantMobileNumber" value={formData.defendantMobileNumber} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>नागरिकता नम्बर</label>
                  <input type="text" name="defendantCitizenNumber" value={formData.defendantCitizenNumber} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>नागरिकता मिति</label>
                  <input type="date" name="defendantCitizenDate" value={formData.defendantCitizenDate} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>हजुरबुवाको नाम</label>
                  <input type="text" name="defendantGrandFatherName" value={formData.defendantGrandFatherName} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>बाबुको नाम</label>
                  <input type="text" name="defendantFatherName" value={formData.defendantFatherName} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>प्रदेश</label>
                  <select
                    name="defendantProvince"
                    value={formData.defendantProvince}
                    onChange={(e) => handleProvinceChange('defendantProvince', e.target.value)}
                    required
                  >
                    <option value="">प्रदेश चयन गर्नुहोस्</option>
                    {provincesData.provinceList.map((province) => (
                      <option key={province.id} value={province.name}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>जिल्ला</label>
                  <select
                    name="defendantDistrict"
                    value={formData.defendantDistrict}
                    onChange={(e) => handleDistrictChange('defendantDistrict', e.target.value)}
                    disabled={!formData.defendantProvince}
                    required
                  >
                    <option value="">जिल्ला चयन गर्नुहोस्</option>
                    {defendantDistricts.map((district) => (
                      <option key={district.id} value={district.name}>
                        {district.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>पालिका</label>
                  <select
                    name="defendantMunicipalities"
                    value={formData.defendantMunicipalities}
                    onChange={handleInputChange}
                    disabled={!formData.defendantDistrict}
                    required
                  >
                    <option value="">पालिका चयन गर्नुहोस्</option>
                    {defendantMunicipalities.map((municipality) => (
                      <option key={municipality.id} value={municipality.name}>
                        {municipality.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>वडाः</label>
                  <input type="number" name="defendantWard" value={formData.defendantWard} onChange={handleInputChange} required />
                </div>
              </div>
            </section>

            {/* Case Details Section */}
            <section className="form-section">
              <h3>मामिला विवरण</h3>
              <div className="form-row">
                <div className="form-group">
                  <label>मामिलको नाम</label>
                  <select name="caseName" value={formData.caseName} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="civil">Civil Case</option>
                    <option value="criminal">Criminal Case</option>
                    <option value="family">Family Case</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>मामिलाको प्रकार</label>
                  <select name="caseType" value={formData.caseType} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="type1">Type 1</option>
                    <option value="type2">Type 2</option>
                    <option value="type3">Type 3</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>मामिला जारी मिति</label>
                  <input type="date" name="caseRegisterDate" value={formData.caseRegisterDate} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>मामिलको चरण</label>
                  <select name="caseStatus" value={formData.caseStatus} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="pending">Pending</option>
                    <option value="active">Active</option>
                    <option value="suspended">Suspended</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>मामिला न</label>
                  <input type="text" name="caseFilingNumber" value={formData.caseFilingNumber} readOnly />
                </div>
                <div className="form-group">
                  <label>पहिलो तारिक मिति</label>
                  <input type="date" name="caseFirstVisit" value={formData.caseFirstVisit} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>पहिलो तारिक समय</label>
                  <input type="time" name="caseVisitTime" value={formData.caseVisitTime} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>मामिला दस्तुर</label>
                  <input type="number" name="caseFee" value={formData.caseFee} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label>ममिला विवरण</label>
                  <textarea name="caseInfo" value={formData.caseInfo} onChange={handleInputChange} required />
                </div>
              </div>
            </section>

            {/* Court Details Section */}
            <section className="form-section">
              <h3>अदालत विवरण</h3>
              <div className="form-row">
                <div className="form-group">
                  <label>अदालतको प्रकार</label>
                  <select name="courtType" value={formData.courtType} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="district">District Court</option>
                    <option value="high">High Court</option>
                    <option value="supreme">Supreme Court</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>अदालतको नाम</label>
                  <select name="courtName" value={formData.courtName} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="court1">Court 1</option>
                    <option value="court2">Court 2</option>
                    <option value="court3">Court 3</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>न्यायाधीशको नाम</label>
                  <select name="judgeName" value={formData.judgeName} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="judge1">Judge 1</option>
                    <option value="judge2">Judge 2</option>
                    <option value="judge3">Judge 3</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>न्यायाधीशको पद</label>
                  <select name="judgePosition" value={formData.judgePosition} onChange={handleInputChange} required>
                    <option value="">चयन गर्नुहोस्</option>
                    <option value="position1">Position 1</option>
                    <option value="position2">Position 2</option>
                    <option value="position3">Position 3</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>कैफियत</label>
                  <textarea name="remarks" value={formData.remarks} onChange={handleInputChange} required />
                </div>
              </div>
            </section>

            <div className="form-buttons">
              <button type="button" onClick={onClose} className="cancel-button">रद्द गर्नुहोस्</button>
              <button type="submit" className="save-button">सुरक्षित गर्नुहोस्</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditModal;