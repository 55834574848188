import React, { useState, useEffect } from "react";
import "../Styles/PageStyles/HomePage.css";
import provincesData from "../Data/ProvincesData";

const HomePage = () => {
  // States for dropdown data
  const [judgePositions, setJudgePositions] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [caseNames, setCaseNames] = useState([]);
  const [judgeName, setJudgeName] = useState([]);
  const [courtTypes, setCourtTypes] = useState([]);
  const [courtNames, setCourtNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all dropdown data on component mount
  useEffect(() => {
    const fetchDropdownData = async () => {
      setIsLoading(true);
      try {
        // Fetch judge positions
        const judgePositionsResponse = await fetch("https://backend.bardagoriyalegalsystem.com/api/judge-positions");
        if (!judgePositionsResponse.ok) {
          throw new Error(`Failed to fetch judge positions: ${judgePositionsResponse.status}`);
        }
        const judgePositionsData = await judgePositionsResponse.json();
        setJudgePositions(judgePositionsData);

        // Fetch case types
        const caseTypesResponse = await fetch("https://backend.bardagoriyalegalsystem.com/api/case-types");
        if (!caseTypesResponse.ok) {
          throw new Error(`Failed to fetch case types: ${caseTypesResponse.status}`);
        }
        const caseTypesData = await caseTypesResponse.json();
        setCaseTypes(caseTypesData);

        // Fetch case names
        const caseNamesResponse = await fetch("https://backend.bardagoriyalegalsystem.com/api/case-names");
        if (!caseNamesResponse.ok) {
          throw new Error(`Failed to fetch case names: ${caseNamesResponse.status}`);
        }
        const caseNamesData = await caseNamesResponse.json();
        setCaseNames(caseNamesData);

        // Fetch judge names
        const judgeNameResponse = await fetch("https://backend.bardagoriyalegalsystem.com/api/judge-names");
        if (!judgeNameResponse.ok) {
          throw new Error(`Failed to fetch judge names: ${judgeNameResponse.status}`);
        }
        const judgeNameData = await judgeNameResponse.json();
        setJudgeName(judgeNameData);

        // Fetch court types
        const courtTypesResponse = await fetch("https://backend.bardagoriyalegalsystem.com/api/court-types");
        if (!courtTypesResponse.ok) {
          throw new Error(`Failed to fetch court types: ${courtTypesResponse.status}`);
        }
        const courtTypesData = await courtTypesResponse.json();
        setCourtTypes(courtTypesData);

        // Fetch court names
        const courtNamesResponse = await fetch("https://backend.bardagoriyalegalsystem.com/api/court-names");
        if (!courtNamesResponse.ok) {
          throw new Error(`Failed to fetch court names: ${courtNamesResponse.status}`);
        }

        const courtNamesData = await courtNamesResponse.json();
        setCourtNames(courtNamesData);

        setError(null);
      } catch (err) {
        console.error("Error fetching dropdown data:", err);
        setError("Failed to load dropdown data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDropdownData();
  }, []);

  const [witness, setWitness] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    DOB: "",
    age: "",
    gender: "",
    mobileNumber: "",
    citizenNumber: "",
    citizenDate: "",
    grandFatherName: "",
    fatherName: "",
    Address: {
      Province: "",
      District: "",
      Municipalities: "",
      Ward: "",
    },
  });

  const [defendant, setDefendant] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    DOB: "",
    age: "",
    gender: "",
    mobileNumber: "",
    citizenNumber: "",
    citizenDate: "",
    grandFatherName: "",
    fatherName: "",
    Address: {
      Province: "",
      District: "",
      Municipalities: "",
      Ward: "",
    },
  });

  const [caseDetails, setCaseDetails] = useState({
    caseName: "",
    caseType: "",
    caseRegisterDate: "",
    caseStatus: "",
    caseFilingNumber: Math.floor(Math.random() * 1000000), // Auto-generated
    caseFirstVisit: "",
    caseVisitTime: "",
    caseFee: "",
    caseInfo: "",
  });

  const [courtDetails, setCourtDetails] = useState({
    courtType: "",
    courtName: "",
    judgeName: "",
    judgePosition: "",
    remarks: "",
  });

  const handleInputChange = (e, section, nestedKey) => {
    const { name, value } = e.target;

    if (nestedKey) {
      if (section === "witness") {
        setWitness((prev) => ({
          ...prev,
          Address: { ...prev.Address, [name]: value },
        }));
      } else if (section === "defendant") {
        setDefendant((prev) => ({
          ...prev,
          Address: { ...prev.Address, [name]: value },
        }));
      }
    } else {
      if (section === "witness") {
        setWitness((prev) => ({ ...prev, [name]: value }));
      } else if (section === "defendant") {
        setDefendant((prev) => ({ ...prev, [name]: value }));
      } else if (section === "caseDetails") {
        setCaseDetails((prev) => ({ ...prev, [name]: value }));
      } else if (section === "courtDetails") {
        setCourtDetails((prev) => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleWitnessAddressChange = (field, value) => {
    setWitness(prev => {
      const newWitness = {
        ...prev,
        Address: { ...prev.Address, [field]: value }
      };

      if (field === 'Province') {
        newWitness.Address.District = '';
        newWitness.Address.Municipalities = '';
      } else if (field === 'District') {
        newWitness.Address.Municipalities = '';
      }

      return newWitness;
    });
  };

  const handleDefendantAddressChange = (field, value) => {
    setDefendant(prev => {
      const newDefendant = {
        ...prev,
        Address: { ...prev.Address, [field]: value }
      };

      if (field === 'Province') {
        newDefendant.Address.District = '';
        newDefendant.Address.Municipalities = '';
      } else if (field === 'District') {
        newDefendant.Address.Municipalities = '';
      }

      return newDefendant;
    });
  };

  const witnessDistricts =
    provincesData.provinceList.find(p => p.name === witness.Address.Province)?.districtList || [];

  const witnessMunicipalities =
    witnessDistricts.find(d => d.name === witness.Address.District)?.municipalityList || [];

  const defendantDistricts =
    provincesData.provinceList.find(p => p.name === defendant.Address.Province)?.districtList || [];

  const defendantMunicipalities =
    defendantDistricts.find(d => d.name === defendant.Address.District)?.municipalityList || [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = { witness, defendant, caseDetails, courtDetails };

    try {
      const response = await fetch("https://backend.bardagoriyalegalsystem.com/api/cases", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        alert("फारम सफलतापूर्वक सबमिट भयो!");
      } else {
        alert(`त्रुटि भयो: ${result.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("नेटवर्क त्रुटि भयो, कृपया पुनः प्रयास गर्नुहोस्!");
    }
  };

  // Helper function to render dropdown options
  const renderOptions = (items) => {
    if (!Array.isArray(items) || items.length === 0) {
      return <option disabled>लोड हुँदैछ...</option>;
    }

    return items.map((item) => (
      <option key={item._id} value={item.name || item.value}>
        {item.name || item.value}
      </option>
    ));
  };

  return (
    <>
      {isLoading && <div className="loading-indicator">Loading data, please wait...</div>}
      {error && <div className="error-message">{error}</div>}
      <div className="form-container">
        <h1>मामिला दर्ता फारम</h1>
        <form onSubmit={handleSubmit}>
          {/* Witness Section */}
          <section className="form-section">
            <h2>प्रथम पक्ष</h2>
            <div className="form-row">
              <div className="form-group">
                <label>पहिलो नाम</label>
                <input type="text" name="firstName" value={witness.firstName} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
              <div className="form-group">
                <label>मध्य नाम</label>
                <input type="text" name="middleName" value={witness.middleName} onChange={(e) => handleInputChange(e, "witness")} />
              </div>
              <div className="form-group">
                <label>थर</label>
                <input type="text" name="lastName" value={witness.lastName} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>जन्म मिति</label>
                <input type="date" name="DOB" value={witness.DOB} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
              <div className="form-group">
                <label>उमेर</label>
                <input type="number" name="age" value={witness.age} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
              <div className="form-group">
                <label>लिङ्ग</label>
                <select name="gender" value={witness.gender} onChange={(e) => handleInputChange(e, "witness")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  <option value="male">पुरुष</option>
                  <option value="female">महिला</option>
                  <option value="others">अर्को</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>मोबाइल नम्बर</label>
                <input type="tel" name="mobileNumber" value={witness.mobileNumber} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
              <div className="form-group">
                <label>नागरिकता नम्बर</label>
                <input type="text" name="citizenNumber" value={witness.citizenNumber} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
              <div className="form-group">
                <label>नागरिकता मिति</label>
                <input type="date" name="citizenDate" value={witness.citizenDate} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>हजुरबुवाको नाम</label>
                <input type="text" name="grandFatherName" value={witness.grandFatherName} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
              <div className="form-group">
                <label>बाबुको नाम</label>
                <input type="text" name="fatherName" value={witness.fatherName} onChange={(e) => handleInputChange(e, "witness")} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>प्रदेश</label>
                <select
                  name="Province"
                  value={witness.Address.Province}
                  onChange={(e) => handleWitnessAddressChange('Province', e.target.value)}
                  required
                >
                  <option value="">प्रदेश चयन गर्नुहोस्</option>
                  {provincesData.provinceList.map((province) => (
                    <option key={province.id} value={province.name}>
                      {province.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>जिल्ला</label>
                <select
                  name="District"
                  value={witness.Address.District}
                  onChange={(e) => handleWitnessAddressChange('District', e.target.value)}
                  disabled={!witness.Address.Province}
                  required
                >
                  <option value="">जिल्ला चयन गर्नुहोस्</option>
                  {witnessDistricts.map((district) => (
                    <option key={district.id} value={district.name}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>पालिका</label>
                <select
                  name="Municipalities"
                  value={witness.Address.Municipalities}
                  onChange={(e) => handleWitnessAddressChange('Municipalities', e.target.value)}
                  disabled={!witness.Address.District}
                  required
                >
                  <option value="">पालिका चयन गर्नुहोस्</option>
                  {witnessMunicipalities.map((municipality) => (
                    <option key={municipality.id} value={municipality.name}>
                      {municipality.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>वडाः</label>
                <input
                  type="number"
                  name="Ward"
                  value={witness.Address.Ward}
                  onChange={(e) => handleInputChange(e, "witness", "Address")}
                  required
                />
              </div>
            </div>
          </section>

          {/* Defendant Section */}
          <section className="form-section">
            <h2>दोश्रो पक्ष</h2>
            <div className="form-row">
              <div className="form-group">
                <label>पहिलो नाम</label>
                <input type="text" name="firstName" value={defendant.firstName} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
              <div className="form-group">
                <label>मध्य नाम</label>
                <input type="text" name="middleName" value={defendant.middleName} onChange={(e) => handleInputChange(e, "defendant")} />
              </div>
              <div className="form-group">
                <label>थर</label>
                <input type="text" name="lastName" value={defendant.lastName} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>जन्म मिति</label>
                <input type="date" name="DOB" value={defendant.DOB} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
              <div className="form-group">
                <label>उमेर</label>
                <input type="number" name="age" value={defendant.age} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
              <div className="form-group">
                <label>लिङ्ग</label>
                <select name="gender" value={defendant.gender} onChange={(e) => handleInputChange(e, "defendant")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  <option value="male">पुरुष</option>
                  <option value="female">महिला</option>
                  <option value="others">अर्को</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>मोबाइल नम्बर</label>
                <input type="tel" name="mobileNumber" value={defendant.mobileNumber} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
              <div className="form-group">
                <label>नागरिकता नम्बर</label>
                <input type="text" name="citizenNumber" value={defendant.citizenNumber} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
              <div className="form-group">
                <label>नागरिकता मिति</label>
                <input type="date" name="citizenDate" value={defendant.citizenDate} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>हजुरबुवाको नाम</label>
                <input type="text" name="grandFatherName" value={defendant.grandFatherName} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
              <div className="form-group">
                <label>बाबुको नाम</label>
                <input type="text" name="fatherName" value={defendant.fatherName} onChange={(e) => handleInputChange(e, "defendant")} required />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>प्रदेश</label>
                <select
                  name="Province"
                  value={defendant.Address.Province}
                  onChange={(e) => handleDefendantAddressChange('Province', e.target.value)}
                  required
                >
                  <option value="">प्रदेश चयन गर्नुहोस्</option>
                  {provincesData.provinceList.map((province) => (
                    <option key={province.id} value={province.name}>
                      {province.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>जिल्ला</label>
                <select
                  name="District"
                  value={defendant.Address.District}
                  onChange={(e) => handleDefendantAddressChange('District', e.target.value)}
                  disabled={!defendant.Address.Province}
                  required
                >
                  <option value="">जिल्ला चयन गर्नुहोस्</option>
                  {defendantDistricts.map((district) => (
                    <option key={district.id} value={district.name}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>पालिका</label>
                <select
                  name="Municipalities"
                  value={defendant.Address.Municipalities}
                  onChange={(e) => handleDefendantAddressChange('Municipalities', e.target.value)}
                  disabled={!defendant.Address.District}
                  required
                >
                  <option value="">पालिका चयन गर्नुहोस्</option>
                  {defendantMunicipalities.map((municipality) => (
                    <option key={municipality.id} value={municipality.name}>
                      {municipality.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>वडाः</label>
                <input
                  type="number"
                  name="Ward"
                  value={defendant.Address.Ward}
                  onChange={(e) => handleInputChange(e, "defendant", "Address")}
                  required
                />
              </div>
            </div>
          </section>

          {/* Case Details Section */}
          <section className="form-section">
            <h2>मामिला विवरण</h2>
            <div className="form-row">
              <div className="form-group">
                <label>मामिलको नाम</label>
                <select name="caseName" value={caseDetails.caseName} onChange={(e) => handleInputChange(e, "caseDetails")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  {Array.isArray(caseNames) && caseNames.length > 0 ? (
                    caseNames.map((name) => (
                      <option key={name._id} value={name.caseName}>
                        {name.caseName}
                      </option>
                    ))
                  ) : (
                    <option disabled>लोड हुँदैछ...</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label>मामिलाको प्रकार</label>
                <select name="caseType" value={caseDetails.caseType} onChange={(e) => handleInputChange(e, "caseDetails")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  {Array.isArray(caseTypes) && caseTypes.length > 0 ? (
                    caseTypes.map((type) => (
                      <option key={type._id} value={type.caseType}>
                        {type.caseType}
                      </option>
                    ))
                  ) : (
                    <option disabled>लोड हुँदैछ...</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label>मामिला जारी मिति</label>
                <input type="date" name="caseRegisterDate" value={caseDetails.caseRegisterDate} onChange={(e) => handleInputChange(e, "caseDetails")} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>मामिलको चरण</label>
                <select name="caseStatus" value={caseDetails.caseStatus} onChange={(e) => handleInputChange(e, "caseDetails")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  <option value="Pending">Pending</option>
                  <option value="Active">Active</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div className="form-group">
                <label>मामिला न</label>
                <input type="text" name="caseFilingNumber" value={caseDetails.caseFilingNumber} readOnly />
              </div>
              <div className="form-group">
                <label>पहिलो तारिक मिति</label>
                <input type="date" name="caseFirstVisit" value={caseDetails.caseFirstVisit} onChange={(e) => handleInputChange(e, "caseDetails")} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>पहिलो तारिक समय</label>
                <input type="time" name="caseVisitTime" value={caseDetails.caseVisitTime} onChange={(e) => handleInputChange(e, "caseDetails")} required />
              </div>
              <div className="form-group">
                <label>मामिला दस्तुर</label>
                <input type="number" name="caseFee" value={caseDetails.caseFee} onChange={(e) => handleInputChange(e, "caseDetails")} required />
              </div>
              <div className="form-group">
                <label>ममिला विवरण</label>
                <textarea name="caseInfo" value={caseDetails.caseInfo} onChange={(e) => handleInputChange(e, "caseDetails")} required />
              </div>
            </div>
          </section>

          {/* Court Details Section */}
          <section className="form-section">
            <h2>अदालत विवरण</h2>
            <div className="form-row">
              <div className="form-group">
                <label>अदालतको प्रकार</label>
                <select name="courtType" value={courtDetails.courtType} onChange={(e) => handleInputChange(e, "courtDetails")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  {Array.isArray(courtTypes) && courtTypes.length > 0 ? (
                    courtTypes.map((type) => (
                      <option key={type._id} value={type.courtType}>
                        {type.courtType}
                      </option>
                    ))
                  ) : (
                    <option disabled>लोड हुँदैछ...</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label>अदालतको नाम</label>
                <select name="courtName" value={courtDetails.courtName} onChange={(e) => handleInputChange(e, "courtDetails")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  {Array.isArray(courtNames) && courtNames.length > 0 ? (
                    courtNames.map((court) => (
                      <option key={court._id} value={court.courtName}>
                        {court.courtName}
                      </option>
                    ))
                  ) : (
                    <option disabled>लोड हुँदैछ...</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label>न्यायाधीशको नाम</label>
                <select name="judgeName" value={courtDetails.judgeName} onChange={(e) => handleInputChange(e, "courtDetails")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  {Array.isArray(judgeName) && judgeName.length > 0 ? (
                    judgeName.map((name) => (
                      <option key={name._id} value={name.judgeName}>
                        {name.judgeName}
                      </option>
                    ))
                  ) : (
                    <option disabled>लोड हुँदैछ...</option>
                  )}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>न्यायाधीशको पद</label>
                <select name="judgePosition" value={courtDetails.judgePosition} onChange={(e) => handleInputChange(e, "courtDetails")} required>
                  <option value="">चयन गर्नुहोस्</option>
                  {Array.isArray(judgePositions) && judgePositions.length > 0 ? (
                    judgePositions.map((position) => (
                      <option key={position._id} value={position.judgePosition}>
                        {position.judgePosition}
                      </option>
                    ))
                  ) : (
                    <option disabled>लोड हुँदैछ...</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label>कैफियत</label>
                <textarea name="remarks" value={courtDetails.remarks} onChange={(e) => handleInputChange(e, "courtDetails")} required />
              </div>
            </div>
          </section>

          <button type="submit" className="submit-button" disabled={isLoading}>
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default HomePage;