import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPrint } from "react-icons/fa";
import "../Styles/ComponentStyles/ViewModal.css";


function ViewModal({ isOpen, onClose, caseData }) {
  const [detailedCase, setDetailedCase] = useState(null);

  useEffect(() => {
    if (isOpen && caseData?.id) {
      // Fetch case data by ID when the modal is open
      axios
        .get(`https://backend.bardagoriyalegalsystem.com/api/cases/${caseData.id}`)
        .then((response) => {
          setDetailedCase(response.data);
        })
        .catch((error) => {
          console.error("Error fetching case details:", error);
        });
    }
  }, [isOpen, caseData]);

  const handlePrint = () => {
    const printWindow = window.open("", "", "height=800,width=1200");
    const content = document.querySelector(".view-modal .case-details").cloneNode(true);

    const style = `
      <style>
  body {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    margin: 20px;
    background-color: #f4f4f9;
  }
  .case-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #007bff;
  }
  .case-section p {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
  }
  .case-section p strong {
    font-weight: bold;
    color: #000;
  }
  .case-section {
    margin-bottom: 20px;
  }
  .view-modal {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  .header img {
    max-width: 120px;
    margin-bottom: 10px;
  }
  .header h1 {
    font-size: 24px;
    margin-bottom: 5px;
    color: #000;
  }
  .header hr {
    border: 0;
    border-top: 2px solid #333;
    margin: 10px 0;
  }

  /* New Styles for Print Layout */
  .case-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  .case-section {
    width: 48%;  /* Adjust the width to fit two sections in a row */
    page-break-inside: avoid; /* Avoid breaking sections in the middle */
  }

  @media print {
    .view-modal {
      padding: 0;
      box-shadow: none;
    }

    .header {
      margin-bottom: 30px;
    }

    .case-details {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .case-section {
      width: 48%;
      page-break-inside: avoid;
    }

    
  }
</style>

    `;

    const headerContent = `
      <div class="header">
        <h1>बर्दगोरिया गाउँपालिका</h1>
        <p>सुदूरपश्चिम प्रदेश, मुडा कैलाली</p>
        <hr />
      </div>
    `;

    printWindow.document.write('<html><head><title>मुद्धा विवरण</title>' + style + '</head><body>');
    printWindow.document.write(headerContent); 
    printWindow.document.write(content.outerHTML);
    printWindow.document.write('</body></html>');

    printWindow.document.close();
    printWindow.print();
  };

  if (!isOpen) return null;

  return (
    <div className="view-modal-overlay">
      <div className="view-modal">
        <div className="modal-header">
          <h2>मुद्धा विवरण</h2>
          <button className="print-btn" onClick={handlePrint}>
              <FaPrint /> Print
            </button>
          <button className="close-btn" onClick={onClose}>X</button>
        </div>
        
        {detailedCase ? (
          <div className="case-details">
            <div className="case-section">
              <h3>प्रथम पक्ष</h3>
              <p><strong>नाम:</strong> {detailedCase.witness.firstName} {detailedCase.witness.middleName} {detailedCase.witness.lastName}</p>
              <p><strong>लिङ्ग:</strong> {detailedCase.witness.gender}</p>
              <p><strong>मोबाइल नम्बर:</strong> {detailedCase.witness.mobileNumber}</p>
              <p><strong>नागरिकता नम्बर:</strong> {detailedCase.witness.citizenNumber}</p>
              <p><strong>हजुरबुवाको नाम:</strong> {detailedCase.witness.grandFatherName}</p>
              <p><strong>बाबुको नाम:</strong> {detailedCase.witness.fatherName}</p>
              <p><strong>ठेगाना:</strong> {detailedCase.witness.Address.Province}, {detailedCase.witness.Address.District}</p>
            </div>

            <div className="case-section">
              <h3>दोश्रो पक्ष</h3>
              <p><strong>नाम:</strong> {detailedCase.defendant.firstName} {detailedCase.defendant.middleName} {detailedCase.defendant.lastName}</p>
              <p><strong>लिङ्ग:</strong> {detailedCase.defendant.gender}</p>
              <p><strong>मोबाइल नम्बर:</strong> {detailedCase.defendant.mobileNumber}</p>
              <p><strong>नागरिकता नम्बर:</strong> {detailedCase.defendant.citizenNumber}</p>
              <p><strong>हजुरबुवाको नाम:</strong> {detailedCase.defendant.grandFatherName}</p>
              <p><strong>बाबुको नाम:</strong> {detailedCase.defendant.fatherName}</p>
              <p><strong>ठेगाना:</strong> {detailedCase.defendant.Address.Province}, {detailedCase.defendant.Address.District}</p>
            </div>

            <div className="case-section">
              <h3>मामिला विवरण</h3>
              <p><strong>मामिलको नाम:</strong> {detailedCase.caseDetails.caseName}</p>
              <p><strong>मामिला न:</strong> {detailedCase.caseDetails.caseFilingNumber}</p>
              <p><strong>मामिलको चरण:</strong> {detailedCase.caseDetails.caseStatus}</p>
              <p><strong>तारिक मिति:</strong> {detailedCase.caseDetails.caseFirstVisit}</p>
              <p><strong>मामिला दस्तुर:</strong> {detailedCase.caseDetails.caseFee}</p>
              <p><strong>ममिला विवरण:</strong> {detailedCase.caseDetails.caseInfo}</p>
            </div>

            <div className="case-section">
              <h3>अदालत विवरण</h3>
              <p><strong>अदालतको नाम:</strong> {detailedCase.courtDetails.courtName}</p>
              <p><strong>न्यायाधीशको नाम:</strong> {detailedCase.courtDetails.judgeName}</p>
              <p><strong>कैफियत:</strong> {detailedCase.courtDetails.remarks}</p>
            </div>

          </div>
        ) : (
          <p>Loading case details...</p>
        )}
      </div>
    </div>
  );
}

export default ViewModal;
