import React, { useState } from "react";
import "../Styles/PageStyles/Login.css"; 
import Logo from "../Img/logo.png"; 
import axios from "axios"; 

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset any previous error

    try {
      // Send login request to the backend API
      const response = await axios.post("https://backend.bardagoriyalegalsystem.com/api/admin/login", {
        username,
        password,
      });

      if (response.status === 200) {
        // Store the JWT token in localStorage
        localStorage.setItem("token", response.data.token);

        // Redirect to the dashboard or homepage
        window.location.href = "/"; // Adjust based on your routing

        console.log("Login successful", response.data);
      }
    } catch (err) {
      console.error("Login error:", err);
      setError("Invalid username or password");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={Logo} alt="Logo" className="logo" />
        <h2>Welcome to <span className="nepali-text">बर्दगोरिया गाउँपालिका</span></h2>
        <h3>Admin Login</h3>
        <form onSubmit={handleSubmit}>
          <label>Username</label>
          <input
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit">LOGIN</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
