import React, { useState, useEffect } from 'react';
import '../Styles/PageStyles/CourtManagement.css';

const CourtManagement = () => {
  const [courtNames, setCourtNames] = useState([]);
  const [courtTypes, setCourtTypes] = useState([]);
  const [courtNameInput, setCourtNameInput] = useState('');
  const [courtTypeInput, setCourtTypeInput] = useState('');
  const [error, setError] = useState('');

  // Fetch court names and court types
  useEffect(() => {
    const fetchCourtNames = async () => {
      try {
        const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/court-names');
        const data = await response.json();
        setCourtNames(data);
      } catch (err) {
        setError('Failed to fetch court names');
      }
    };

    const fetchCourtTypes = async () => {
      try {
        const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/court-types');
        const data = await response.json();
        setCourtTypes(data);
      } catch (err) {
        setError('Failed to fetch court types');
      }
    };

    fetchCourtNames();
    fetchCourtTypes();
  }, []);

  const addCourtName = async () => {
    try {
      const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/court-names', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ courtName: courtNameInput }),
      });

        const data = await response.json();
        console.log("Response Data:", data); 

        if (!response.ok) {
            throw new Error(data.message || 'Failed to add court name');
        }

        setCourtNames([...courtNames, data]);
        setCourtNameInput('');
    } catch (err) {
        console.error("Error adding court name:", err);
        setError('Failed to add court name');
    }
};

  // Add court type
  const addCourtType = async () => {
    try {
      const response = await fetch('https://backend.bardagoriyalegalsystem.com/api/court-types', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ courtType: courtTypeInput }),
      });
      const data = await response.json();
      setCourtTypes([...courtTypes, data]);
      setCourtTypeInput('');
    } catch (err) {
      setError('Failed to add court type');
    }
  };

  // Delete court name
  const deleteCourtName = async (id) => {
    try {
      const response = await fetch(`https://backend.bardagoriyalegalsystem.com/api/court-names/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setCourtNames(courtNames.filter(court => court._id !== id));
      } else {
        setError('Failed to delete court name');
      }
    } catch (err) {
      setError('Failed to delete court name');
    }
  };

  // Delete court type
  const deleteCourtType = async (id) => {
    try {
      const response = await fetch(`https://backend.bardagoriyalegalsystem.com/api/court-types/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setCourtTypes(courtTypes.filter(court => court._id !== id));
      } else {
        setError('Failed to delete court type');
      }
    } catch (err) {
      setError('Failed to delete court type');
    }
  };

  return (
    <div className="court-management-container">
      <h1>अदालत व्यवस्थापन</h1>
      {error && <div className="error">{error}</div>}

      <div className="forms-container">
        <div className="form-box">
          <h2>अदालतको नाम</h2>
          <input
            type="text"
            value={courtNameInput}
            onChange={(e) => setCourtNameInput(e.target.value)}
            placeholder="Enter Court Name"
          />
          <button onClick={addCourtName}>अदालतको नाम</button>
        </div>

        <div className="form-box">
          <h2>अदालतको प्रकार</h2>
          <input
            type="text"
            value={courtTypeInput}
            onChange={(e) => setCourtTypeInput(e.target.value)}
            placeholder="Enter Court Type"
          />
          <button onClick={addCourtType}>अदालतको प्रकार</button>
        </div>
      </div>

      <div className="tables-container">
        <div className="table-box">
          <h2>अदालतको नाम</h2>
          <table>
            <thead>
              <tr>
                <th>अदालतको नाम</th>
                <th>कार्य</th>
              </tr>
            </thead>
            <tbody>
              {courtNames.map((court) => (
                <tr key={court._id}>
                  <td>{court.courtName}</td>
                  <td><button className="delete-btn" onClick={() => deleteCourtName(court._id)}>Delete</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-box">
          <h2>अदालतको प्रकार</h2>
          <table>
            <thead>
              <tr>
                <th>अदालतको प्रकार</th>
                <th>कार्य</th>
              </tr>
            </thead>
            <tbody>
              {courtTypes.map((court) => (
                <tr key={court._id}>
                  <td>{court.courtType}</td>
                  <td><button className="delete-btn" onClick={() => deleteCourtType(court._id)}>Delete</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CourtManagement;
